import { global } from ":global";
import { _Firebase } from "./_model";

export async function onWordCloudUpdate(
  props: _Firebase.OnWorldCloudUpdateProps
) {
  global.fb.rtdb.onValueChanged({
    path: "/wordcloud/" + String(props.eventId),
    callback: (snap) => {
      props.onUpdate(snap.val());
    },
  });
}
